import ReactMarkdown from 'react-markdown'
import {useEffect, useState} from "react";
import markdown from './index.md';
import remarkGfm from 'remark-gfm'
import remarkHeadingID from 'remark-heading-id' 
import styles from "./GetStarted.module.sass";

export default function Content() { 
    const [content, setContent] = useState(null)
    useEffect(()=>{
        fetch(markdown).then(res => res.text()).then(text => setContent(text));
    },[])

    return (
        <ReactMarkdown className={styles.documentmd} children={content} remarkPlugins={[remarkGfm, remarkHeadingID]}/>
    )
}