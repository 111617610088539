import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector';
import getTranslations from "./getTranslations";

const i18nInstance = i18n.createInstance()
const translations = getTranslations(true, false);

i18nInstance
    .use(LanguageDetector)
    .init({
        resources: translations,
        react: {
            useSuspense: false,
        }
    })

export default i18nInstance
