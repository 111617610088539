import React, { useState } from "react";
import cn from "classnames";
import styles from "./Standard.module.sass";
import { Icon } from "../../../components";

const options = [
  {
    title: "Main features",
    items: [
      {
        title: "User Friendly",
        description: "Some Text",
      },
      {
        title: "Cross-App Support",
        description: "Some Text",
      },
      {
        title: "Crypto Payments",
        description: "Some Text",
      },
      {
        title: "Multiple transaction processing in Real-time",
        description: "Some Text",
      },
      {
        title: "Flexible permissions settings",
        description: "Some Text",
      },
      {
        title: "Detailed history of transactions and sessions",
        description: "Some Text",
      },
      {
        title: "Decentralized",
        description: "Some Text",
      },
      {
        title: "Respect Privacy",
        description: "Some Text",
      },
    ],
  },
];

const data = [
  {
    title: "Velas Account",
    color: "#0037C1",
    price: "0",
    note: "per month",
    options: [
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
      "true",
    ],
  },
  {
    title: "Metamask",
    color: "#FF592C",
    price: "20.88",
    note: "per month",
    options: [
      "true",
      "true",
      "true",
      "false",
      "false",
      "false",
      "true",
      "true",
    ],
  },
  {
    title: "Email/Password",
    color: "#777E90",
    options: [
      "true",
      "true",
      "false",
      "false",
      "false",
      "false",
      "false",
      "false",
    ],
  },
];

const Standard = () => {
  const [more, setMore] = useState([false, false, false]);

  const handleClick = (index) => {
    let newMore = [...more];
    newMore[index] = !more[index];

    setMore(newMore);
  };

  const renderContent = (content) => {
    if (content === "true") {
      return <Icon name="check" size="14" />;
    }
    if (content === "false") {
      return <div className={styles.minus}>-</div>;
    }
    return <div className={styles.minus}>{content}</div>;
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("stage", styles.stage)}>
        Account Standard with velas account
        </div>
        <h2 className={cn("h2", styles.title)}>
        Account Standard
        </h2>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.head}></div>
              <div className={styles.body}>
                {options.map((option, index) => (
                  <div className={styles.item} key={index}>
                    <div className={styles.category}>{option.title}</div>
                    {option.items.map((item, index) => (
                      <div className={styles.parameter} key={index}>
                        <div className={styles.label}>{item.title}</div>
                        {/* <div className={styles.hint}>
                          <Icon name="info" size="10" />
                          <div className={styles.tooltip}>
                            {item.description}
                          </div>
                        </div> */}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            {data.map((type, index) => (
              <div className={styles.col} key={index}>
                <div className={styles.head}>
                  <div className={styles.package} style={{ color: type.color }}>
                    {type.title}
                  </div>
                  <div className={styles.description}>{type.description}</div>
                  
                </div>
                <div className={styles.body}>
                  <div
                    className={cn(styles.more, {
                      [styles.active]: more[index],
                    })}
                    onClick={() => handleClick(index)}
                  >
                    See all features
                    <Icon name="arrow-bottom" size="9" />
                  </div>
                  <div
                    className={cn(styles.list, {
                      [styles.visible]: more[index],
                    })}
                  >
                    {options.map((option, optionIndex) => (
                      <div className={styles.item} key={optionIndex}>
                        {option.items.map((item, itemIndex) => (
                          <div className={styles.parameter} key={itemIndex}>
                            <div className={styles.label}>{item.title}</div>
                            {renderContent(type.options[itemIndex])}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                  
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Standard;
