import React from "react";
import cn from "classnames";
import styles from "./Partners.module.sass";
import { ScrollParallax } from "../../../components";
import { Ferrari, Velhalla, SpaceChain, Dextools, Travala, BCvault, CoinPayments, Wagyu } from "../../../assets/images/content";


const Partners = () => {
  return (
    <div className={styles.clients}>
      <div className={cn("container", styles.container)}>
        <div className={styles.list}>
          <div className={styles.logo}>
            <img src={Ferrari} alt="ferrari" />
          </div>
          <div className={styles.logo}>
            <img src={Velhalla} alt="velhalla" />
          </div>
          <div className={styles.logo}>
            <img src={SpaceChain} alt="spacechain" />
          </div>
          <div className={styles.logo}>
            <img src={Dextools} alt="dextools" />
          </div>
          <div className={styles.logo}>
            <img src={Travala} alt="travala" />
          </div>
          <div className={styles.logo}>
            <img src={BCvault} alt="bcvault" />
          </div>
          <div className={styles.logo}>
            <img src={CoinPayments} alt="coinpayments" />
          </div>
          <div className={styles.logo}>
            <img src={Wagyu} alt="wagyu" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
