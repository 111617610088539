import {
    TransactionInstruction,
    PublicKey,
    SystemProgram,
    SYSVAR_RENT_PUBKEY,
} from '@solana/web3.js';
import * as Web3 from "@velas/web3";
import {RelyingPartyData, RelyingInstruction, VPublicKey, RelatedProgramInfo} from './instruction'
import BN from "bn.js";

const crypto = require('crypto');
const RELYING_CODE_ADDRESS = new PublicKey("VRPLtk4k31bDL99mn1A5mE96CUUzQ9PnftEwf2LvMiG");


function getRedirectUriHash(programRedirectUri) {
    crypto.createHash('sha256');
    let hasher = crypto.createHash('sha256');
    for (let uri of programRedirectUri) {
        hasher.update(uri.toString());
    }

    return hasher.digest();
}

/**
 * Generate RelyingParty address.
 *
 * @param owner genesis owner of the RelyingParty
 * @returns RelyingParty Public Key
 */
async function generateAccountAddress(
    programName,
    programIconCid,
    programDomainName,
    programRedirectUri,
) {
    const redirect_uri_hash = getRedirectUriHash(programRedirectUri);
    return await PublicKey.findProgramAddress(
        [
            Buffer.from(programName.slice(0, Math.min(32, programName.length))),
            Buffer.from(programIconCid.slice(0, Math.min(32, programIconCid.length))),
            Buffer.from(programDomainName.slice(0, Math.min(32, programDomainName.length))),
            redirect_uri_hash.slice(0, Math.min(32, redirect_uri_hash.length))
        ],
        RELYING_CODE_ADDRESS
    );
}

/**
 * Generate signers from accounts.
 *
 * @param accounts accounts that will become signers
 * @returns Signers
 */
function accountsToSigners(accounts) {
    const signers = [];
    for (const account of accounts) {
        signers.push({pubkey: account.publicKey, isSigner: true, isWritable: false})
    }

    return signers;
}

/**
 * Get parsed RelyingParty.
 *
 * @param connection connection to the node
 * @param relyingPartyAddress Public Key
 * @returns Parsed RelyingPartyData struct
 */
async function getRelyingParty(connection, relyingPartyAddress) {
    const relyingInfo = await connection.getAccountInfo(relyingPartyAddress);

    return RelyingPartyData.decode(relyingInfo.data);
}

/**
 * Get parsed RelyingParty unckecked.
 *
 * @param connection connection to the node
 * @param relyingPartyAddress Public Key
 * @returns Parsed struct
 */
async function getRelyingPartyUnchecked(connection, relyingPartyAddress) {
    const relyingInfo = await connection.getAccountInfo(relyingPartyAddress);

    if (!relyingInfo || !relyingInfo.data.length) {
        return RelyingPartyData.default();
    }

    return RelyingPartyData.decode(relyingInfo.data);
}

/**
 * Create Account to RelyingParty instruction.
 *
 * @param owner new owner of the  RelyingParty
 * @returns `SetAuthority` TransactionInstruction
 */
async function createAccount(
    owner,
    programName,
    programIconCid,
    programDomainName,
    programRedirectUri,
) {
    const relyingPartyAddress = await generateAccountAddress(programName, programIconCid, programDomainName, programRedirectUri);
    const keys = [
        {pubkey: relyingPartyAddress[0], isSigner: false, isWritable: true},
        {pubkey: owner, isSigner: false, isWritable: false},
        {pubkey: SYSVAR_RENT_PUBKEY, isSigner: false, isWritable: false},
        {pubkey: SystemProgram.programId, isSigner: false, isWritable: false},
    ];
    const {Connection} = Web3;
    const connection = new Connection(process.env.REACT_APP_NETWORK_HOST, 'singleGossip');
    const relyingPartyData = new RelyingPartyData({
        version: new BN(1),
        authority: VPublicKey.empty(),
        related_program_data: new RelatedProgramInfo({
            name: programName,
            icon_cid: programIconCid,
            domain_name: programDomainName,
            redirect_uri: programRedirectUri
        })
    }).encode();
    const rentLamports = await connection.getMinimumBalanceForRentExemption(relyingPartyData.length)

    return {
        address: relyingPartyAddress,
        lamports: rentLamports,
        instruction: new TransactionInstruction({
            programId: RELYING_CODE_ADDRESS,
            keys: keys,
            data: RelyingInstruction.createAccount(
                programName,
                programIconCid,
                programDomainName,
                programRedirectUri,
                relyingPartyAddress[1],
            ).encode(),
        })
    };
}

/**
 * Set Authority to RelyingParty instruction.
 *
 * @param owner new owner of the  RelyingParty
 * @returns `SetAuthority` TransactionInstruction
 */
async function setAuthority(relyingPartyAddress, owner, newAuthority) {
    const keys = [
        {pubkey: relyingPartyAddress, isSigner: false, isWritable: true},
        {pubkey: owner, isSigner: true, isWritable: false},
        {pubkey: newAuthority, isSigner: false, isWritable: false},
    ];

    return new TransactionInstruction({
        programId: RELYING_CODE_ADDRESS,
        keys: keys,
        data: RelyingInstruction.setAuthority().encode(),
    });
}

/**
 * Close Account to RelyingParty instruction.
 *
 * @param owner owner of the  RelyingParty
 * @returns `CloseAccount` TransactionInstruction
 */
async function closeAccount(relyingPartyAddress, owner, destination) {
    const keys = [
        {pubkey: relyingPartyAddress, isSigner: false, isWritable: true},
        {pubkey: owner, isSigner: true, isWritable: false},
        {pubkey: destination, isSigner: false, isWritable: false},
    ];

    return new TransactionInstruction({
        programId: RELYING_CODE_ADDRESS,
        keys: keys,
        data: RelyingInstruction.closeAccount().encode(),
    });
}

export default createAccount;