import styles from "./404.module.sass"
import cn from "classnames";
import { Link } from 'react-router-dom'

import { Error } from "../../assets/images/content";


export default function Page404() {
    return <>
    <div className={styles.error}>
        <div className={styles.head}>
            <img src={Error} alt="download" />
            <h2 className={cn("h2", styles.title)}>
                Ooops..
            </h2>
            <div className={styles.info}>
            Error 404. Page not found
            </div>
        </div>
        <div className={styles.tabs}>
            <Link className={styles.tab} to="/">
                <div className={styles.btn}>Back to home</div>
            </Link>
        </div>
    </div>
    </>
}