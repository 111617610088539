import "./index.sass"
import {useState} from "react";

export default function Input({label, description, errorMessage, onChange, type, onBlur, triggerError, invalid, ...inputProps}) {
    const [focused, setFocused] = useState(false);

    const handleFocus = (e) => {
        setFocused(true);
        onBlur();
    };

    return (
        <div className="input-wrapper">
            <label className="input-label">
                <span className="input-label-title">{label}</span>
                {description ? <span className="input-label-description">{description}</span> : ""}
                {type === "textarea" ?
                    <textarea
                        className="textarea"
                        onChange={onChange}
                        onBlur={handleFocus}
                        failedvalidation={invalid}
                        focused={triggerError === "true" ? triggerError : focused.toString()}
                        {...inputProps}
                    />
                    :
                    <input
                        className="input"
                        onChange={onChange}
                        onBlur={handleFocus}
                        type={type}
                        failedvalidation={invalid}
                        focused={triggerError === "true" ? triggerError : focused.toString()}
                        {...inputProps}
                    />
                }
                <span className="input-error">{errorMessage}</span>
            </label>
        </div>
    );
}