import "./index.sass"
import {useState} from "react";
import Input from "../input";
import { CID } from 'multiformats/cid'

export default function Form({stages, onSubmit, confirmation}) {
    const initialFormData = {};
    stages.forEach(stage => {
        stage.fields.forEach(field => {
            initialFormData[field.propertyName || field.label] = {
                value: "",
                invalid: field.required ? "true" : "false"
            }
        })
    });
    const [stage, setStage] = useState(stages[0].title);
    const [formData, setFormData] = useState(initialFormData);
    const [triggerError, setTriggerError] = useState("false");

    function onChange(e, field) {
        setFormData((current) => {
            const result = {...current};
            result[field.propertyName || field.label].value = e.target.value;
            return result;
        });
    }

    function formHeader() {
        return <div className="rp-form-header">
            {stages.map(formStage=>(
                <h6 key={"rp-form-header"+formStage.title} className={`rp-form-header-item ${stage === formStage.title ? "rp-form-header-item-active" : ""}`}>{formStage.title}</h6>
            ))}
            {confirmation ? <h6 className={`rp-form-header-item ${stage === "confirmation" ? "rp-form-header-item-active" : ""}`}>Confirmation</h6> : ""}
        </div>
    }

    return (
        stage === "confirmation" && confirmation ?
            <div className="rp-form-confirmation">
                {formHeader()}
                <p className="rp-form-confirmation-text">
                    {confirmation.description}
                </p>
                <ul className="rp-form-confirmation-list">
                    {Object.keys(formData).map((field,index) => (
                        <li className="rp-form-confirmation-list-item" key={field+index}>
                            <span className="rp-form-confirmation-list-item-label">{field}</span>
                            <span className="rp-form-confirmation-list-item-value">{formData[field].value || "-"}</span>
                        </li>
                    ))}
                </ul>
                <div className="rp-form-item-buttons">
                    <button className="rp-form-item-button rp-form-item-button-back" onClick={()=>{
                        setStage(stages[stages.length - 1].title)
                    }}>Back</button>
                    <button className="rp-form-item-button" onClick={() => {
                        console.log("form data", formData)
                        const result = {};
                        for (const [key, value] of Object.entries(formData)) {
                            result[key] = value.value;
                        }
                        onSubmit(result);
                    }}>Confirm
                    </button>
                </div>
            </div>
            :
            <form className="rp-form" onSubmit={event => {
                event.preventDefault();
            }}>
                {formHeader()}
                {stages.map((formStage, index) => (
                    <div key={formStage.title}
                         className={`rp-form-item ${stage === formStage.title ? "rp-form-item-active" : ""}`}>
                        {formStage.fields.map((field, index) => (
                            <Input onChange={(e) => onChange(e, field)}
                                   type={field.type}
                                   key={field.label + index}
                                   label={field.label}
                                   value={formData[field.propertyName || field.label].value}
                                   placeholder={field.placeholder}
                                   invalid={formData[field.propertyName || field.label].invalid}
                                   description={field.description || null}
                                   onBlur={()=>{
                                       if (field.required && field.cidValidation && formData[field.propertyName || field.label].value) {
                                         try {
                                             CID.parse(formData[field.propertyName || field.label].value);
                                             setFormData(current => {
                                                 const result = {...current};
                                                 result[field.propertyName || field.label].invalid = "false";
                                                 return result;
                                             })
                                         }  catch (e) {
                                             setFormData(current => {
                                                 const result = {...current};
                                                 result[field.propertyName || field.label].invalid = "true";
                                                 return result;
                                             })
                                         }
                                       } else if ((field.required && field.regex && !field.regex.test(formData[field.propertyName || field.label].value)) || (field.required && !formData[field.propertyName || field.label].value)) {
                                           setFormData(current => {
                                               const result = {...current};
                                               result[field.propertyName || field.label].invalid = "true";
                                               return result;
                                           })
                                       } else {
                                           setFormData(current => {
                                               const result = {...current};
                                               result[field.propertyName || field.label].invalid = "false";
                                               return result;
                                           })
                                       }
                                   }}
                                   triggerError={triggerError}
                            />
                        ))}
                        <div className="rp-form-item-buttons">
                            {stage !== stages[0].title ?
                                <button type="button" className="rp-form-item-button rp-form-item-button-back"
                                        onClick={() => {
                                            setStage(stages[index - 1].title)
                                        }}>Back</button>
                                : ""
                            }
                            <button type="submit" className="rp-form-item-button rp-form-item-button-next"
                                    onClick={() => {
                                        let allFieldsAreValid = true;
                                        formStage.fields.forEach(field => {
                                            if (formData[field.propertyName || field.label].invalid === "true") {
                                                allFieldsAreValid = false;
                                            }
                                        })
                                        if (allFieldsAreValid) {
                                            setTriggerError("false");
                                            if (index === stages.length - 1 && confirmation) {
                                                setStage("confirmation")
                                            } else if (index === stages.length - 1) {
                                                onSubmit();
                                            } else {
                                                setStage(stages[index + 1].title)
                                            }
                                        } else {
                                            setTriggerError("true");
                                            console.log("fields are invalid")
                                        }
                                    }}>Next
                            </button>
                        </div>
                    </div>
                ))}

            </form>
    )
}