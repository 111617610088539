import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import { Icon } from "../index"
import LogoDark from "../../assets/images/logo-dark.svg";

const menu = [
  // {
  //     title: "Relying Party",
  //     url: "/relying-party",
  // },
  {
      title: "Download",
      url: "/download",
  },
  {
      title: "Community",
      url: "/",
  },
  {
    title: "Documentation",
    url: "/docs",
  },
];

const socials = [
  {
    title: "facebook",
    size: "16",
    url: "/",
  },
  {
    title: "twitter",
    size: "18",
    url: "/",
  },
  {
    title: "instagram",
    size: "16",
    url: "/",
  },
  {
    title: "dribbble",
    size: "16",
    url: "/",
  },
  {
    title: "behance",
    size: "20",
    url: "/",
  },
];

const Footer = () => {
  const [visible, setVisible] = useState(false);

  return (
    <footer className={styles.footer}>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.col}>
            <div className={styles.box}>
              <Link className={styles.logo} to="/">
                <img className={styles.pic} src={LogoDark} alt="Velas Account" />
              </Link>
            </div>
            
          </div>
          <div className={styles.col}>
            <div className={styles.category}>contact</div>
            <div className={styles.info}>
              <p>43252 Borer Mountains</p>
              <p>Zackerychester</p>
              <p>Bahamas</p>
              <p>732-528-4945</p>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.category}>velas account</div>
            <div className={styles.info}>
            A new way of trusted authentication to Web 3.0 Ecosystem

            </div>
            
          </div>
        </div>
      </div>
      <div className={styles.foot}>
        <div className={cn("container", styles.container)}>
          <div className={styles.copyright}>
            Copyright © 2022 VelasAccount. All rights reserved
          </div>
          <div className={styles.socials}>
            {socials.map((x, index) => (
              <a
                className={styles.social}
                href={x.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <Icon name={x.title} size={x.size} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
