import styles from "./Header.module.sass"
import StoreContext from "../../store/RootStore";
import cn from "classnames";
import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import {Image, Icon} from "../index"
import LogoDark from "../../assets/images/logo-dark.svg";
import { useLocation } from 'react-router-dom';

function Header({children}) {
    const {LangStore, AccountStore, FunctionStore} = StoreContext();
    const [visibleNav, setVisibleNav] = useState(false);
    const location = useLocation();

    const navLinks = [
        {
            title: "Download",
            url: "/download",
        },
        {
            title: "Community",
            url: "/",
        },
        {
            title: "Documentation",
            url: "/docs",
        },
    ];

    const socials = [
        {
            title: "facebook",
            size: "16",
            url: "/",
        },
        {
            title: "twitter",
            size: "18",
            url: "/",
        },
        {
            title: "instagram",
            size: "16",
            url: "/",
        },
    ];

    useEffect(() => {
        if (location && location.pathname.includes("relying-party")){
            FunctionStore.checkActiveSession();
        }
    }, []);

    return (
        <header className={styles.header}>
            <div className={cn("container", styles.container)}>
                <Link
                    className={styles.logo}
                    to="/"
                    onClick={() => setVisibleNav(false)}
                >
                    <img className={styles.pic} src={LogoDark} alt="Velas Account"/>
                </Link>
                <div className={cn(styles.wrap, {[styles.active]: visibleNav})}>
                    <nav className={styles.nav}>
                        {navLinks.map((x, index) =>
                            <NavLink
                                className={({isActive}) => isActive ? styles.active : styles.link}
                                // activeClassName={styles.active}
                                to={x.url}
                                key={index}
                                onClick={() => setVisibleNav(false)}
                            >
                                {x.title}
                            </NavLink>
                        )}
                    </nav>
                    <div className={styles.details}>

                        <div className={styles.socials}>
                            {socials.map((x, index) => (
                                <a
                                    className={styles.social}
                                    href={x.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={index}
                                >
                                    <Icon name={x.title} size={x.size}/>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
                {location && location.pathname.includes("relying-party") ?
                    <>
                        {AccountStore.getCurrentSession() ?
                            <div className={cn("button-stroke button-small", styles.dropdown)}>
                                <Icon name="user" size="14"/>
                                <span className={styles.address}>{AccountStore.getCurrentSession().access_token_payload.sub.slice(0,4)}..{AccountStore.getCurrentSession().access_token_payload.sub.substr(-4)}</span>
                                <div className={styles.dropdown_content}>
                                    <button className={cn("button-stroke button-small", styles.dropdown_button)}
                                            onClick={()=>AccountStore.logout()}
                                    >Log Out</button>
                                </div>
                            </div>
                            :
                            <button className={cn("button-stroke button-small sign-in-button", styles.button)}
                                    onClick={()=>{FunctionStore.login()}}
                            >
                                Sign In
                            </button>
                        }
                    </> : ""
                }
                <button
                    className={cn(styles.burger, {[styles.active]: visibleNav})}
                    onClick={() => setVisibleNav(!visibleNav)}
                ></button>
            </div>
        </header>
    )
}

export default observer(Header)