import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import styles from "./Integ.module.sass";
import { Int } from "../../../assets/images/content";
import { Icon, ScrollParallax } from "../../../components";


const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Integ = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 100000,
        settings: "unslick",
      },
    ],
  };

  return (
    <div className={cn("section-pb", styles.section)}>
      <div className={cn("container section-shadow", styles.container)}>

        <div className={styles.wrap}>
          <ScrollParallax className={styles.item}>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles.details}>
                  {/* <div className={styles.number}>0{index + 1}.</div> */}
                  <h2 className={cn("h3", styles.category)}>Integration made easy</h2>
                  <div className={styles.content}>Private and passwordless authentication with crypto payment infrastructure on your website in a few easy steps.</div>
                  <div className={styles.btns}>
                    <Link className="button-small button-stroke" to="/docs">
                      <span className={styles.mobile}>Our Documentation</span>
                      <span className={styles.desktop}>Official Velas Account Documentation</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className={styles.col}>
                <img
                  src={Int}
                  alt="Integration"
                />
              </div>
            </div>
          </ScrollParallax>
        </div>
      </div>
    </div>
  );
};

export default Integ;
