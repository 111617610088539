import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import App from './App'
import Page404 from "./pages/404";
import Landing from "./pages/Landing";
import Downloads from "./pages/Downloads";
import Documentation from "./pages/Documentation";
import WebApp from "./pages/Documentation/GetStarted";
import RelyingParty from "./pages/RelyingParty";
import Header from "./components/header";
import Footer from "./components/footer";

export default function AppRoutes() {
    return (
        <App>
            <Router>
                <Header />
                <Routes>
                    <Route path="/" element={<Landing/>}/>
                    <Route path="/download" element={<Downloads/>}/>
                    <Route path="/docs" element={<Documentation/>}/>
                    <Route path="/docs/web" element={<WebApp/>}/>
                    <Route path="/relying-party" element={<RelyingParty/>}/>
                    <Route path="*" element={<Page404/>}/>
                </Routes>
                <Footer />
            </Router>
        </App>
    );
}
