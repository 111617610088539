import React from 'react';
import ReactDOM from 'react-dom/client';

import AppRoutes from './routes'
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <AppRoutes />
    </React.StrictMode>
);
reportWebVitals();
