import React from "react";
import cn from "classnames";
import styles from "./GetStarted.module.sass";
import Content from "./content";

const GetStarted = ({ scrollToRef }) => {
  return (
    <div className={styles.getstarted}>
      <div className={cn("container", styles.container)}>
        <Content className="documentContentMd"/>
      </div>
    </div>
  );
};

export default GetStarted;
