import { Enum, Assignable, SCHEMA } from './solana-borsh';
import {
    PublicKey,
} from '@solana/web3.js';

import BN from "bn.js";

export class CreateAccount extends Assignable {
    programName;
    programIconCid;
    programDomainName;
    programRedirectUri;
    bumpSeedNonce;
}

export class SetAuthority extends Assignable {}

export class CloseAccount extends Assignable {}

export class RelyingInstruction extends Enum {
    createAccount
    setAuthority
    closeAccount

    static createAccount(
        programName,
        programIconCid,
        programDomainName,
        programRedirectUri,
        bumpSeedNonce
    ){
        return new RelyingInstruction({ createAccount: new CreateAccount({
                programName,
                programIconCid,
                programDomainName,
                programRedirectUri,
                bumpSeedNonce,
            }) });
    }

    static setAuthority(){
        return new RelyingInstruction({ setAuthority: new SetAuthority({}) });
    }

    static closeAccount(){
        return new RelyingInstruction({ closeAccount: new CloseAccount({}) });
    }
}

export class RelatedProgramInfo extends Assignable {
    name
    icon_cid
    domain_name
    redirect_uri

    static default(){
        return new RelatedProgramInfo({
            name: "",
            icon_cid: [],
            domain_name: "",
            redirect_uri: []
        });
    }
}

export class RelyingPartyData extends Assignable {
    version
    authority
    related_program_data

    static default(){
        return new RelyingPartyData({
            version: new BN(0),
            authority: VPublicKey.empty(),
            related_program_data: RelatedProgramInfo.default(),
        });
    }
}


export class VPublicKey extends Assignable {
    // The public key bytes
    bytes

    toPublicKey(){
        return new PublicKey(this.bytes);
    }

    static parse(pubkey){
        return VPublicKey.fromPublicKey(new PublicKey(pubkey));
    }

    static fromPublicKey(publicKey){
        return new VPublicKey({ bytes: Uint8Array.from(publicKey.toBuffer()) });
    }

    static empty(){
        const bytes = new Array(32);
        bytes.fill(0);
        return new VPublicKey({ bytes });
    }
}

SCHEMA.set(RelatedProgramInfo, {
    kind: 'struct',
    field: 'struct',
    fields: [
        ['name', 'String'],
        ['icon_cid', ['u8']],
        ['domain_name', 'String'],
        ['redirect_uri', ['String']],
    ],
});

SCHEMA.set(VPublicKey, {
    kind: 'struct',
    fields: [['bytes', [32]]],
});

SCHEMA.set(RelyingPartyData, {
    kind: 'struct',
    field: 'struct',
    fields: [
        ['version', 'u8'],
        ['authority', VPublicKey],
        ['related_program_data', RelatedProgramInfo],
    ],
});

SCHEMA.set(RelyingInstruction, {
    kind: 'enum',
    field: 'enum',
    values: [
        ['createAccount', CreateAccount],
        ['setAuthority', SetAuthority],
        ['closeAccount', CloseAccount],
    ],
});

SCHEMA.set(CreateAccount, { kind: 'struct', fields: [
        ['programName', 'String'],
        ['programIconCid', 'String'],
        ['programDomainName', 'String'],
        ['programRedirectUri', ['String']],
        ['bumpSeedNonce', 'u8'],
    ] });

SCHEMA.set(SetAuthority, { kind: 'struct', fields: [] });
SCHEMA.set(CloseAccount, { kind: 'struct', fields: [] });