import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Solutions.module.sass";
import Slider from "react-slick";
import { Icon, ScrollParallax } from "../../../components";
import { User, Medal, Lightning } from "../../../assets/images/content";


const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Solutions = ({ classNameTitle, title, classNameSection, scrollToRef }) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={cn("section-pb", styles.section)}>
      <div className={styles.anchor} ref={scrollToRef}></div>
      <div className={cn("container section-bg", styles.container)}>
        <div className={styles.top}>
        <div className={cn("stage", styles.stage)}>
            Velas Account`s
          </div>
          <h2 className={cn("h2", styles.title)}>Solutions</h2>
          
        </div>
        <div className={styles.wrap}>
          <Slider className="programs-slider" {...settings}>
            <ScrollParallax className={styles.slide}>
              <div className={cn("programs-item", styles.item)}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: "#45B26B" }}
                  >
                  <img src={User} alt="user" />
                </div>
                {/* <div className={styles.subtitle}>One-click Login</div> */}
                <div className={styles.content}>One-click Login to all blockchain services.</div>
              </div>
            </ScrollParallax>
            <ScrollParallax className={styles.slide}>
              <div className={cn("programs-item", styles.item)}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: "#9757D7" }}
                  >
                  <img src={Medal} alt="medal-1" />
                </div>
                {/* <div className={styles.subtitle}>Premier user experience with increasing crypto adoption.</div> */}
                <div className={styles.content}>Premier user experience with increasing crypto adoption.</div>
              </div>
            </ScrollParallax>
            <ScrollParallax className={styles.slide}>
              <div className={cn("programs-item", styles.item)}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: "#3772FF" }}
                  >
                  <img src={Lightning} alt="lightning" />
                </div>
                {/* <div className={styles.subtitle}>User-friendly mobile payment in crypto and fiat currencies.</div> */}
                <div className={styles.content}>User-friendly mobile payment in crypto and fiat currencies.</div>
              </div>
            </ScrollParallax>
            <ScrollParallax className={styles.slide}>
              <div className={cn("programs-item", styles.item)}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: "#45B26B" }}
                  >
                  <img src={User} alt="user" />
                </div>
                {/* <div className={styles.subtitle}>Easy integration for services and applications.</div> */}
                <div className={styles.content}>Easy integration for services and applications.</div>
              </div>
            </ScrollParallax>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
