import "./index.sass"
import {useState} from "react";

export default function Onboarding({stages, finalize}) {
    const [stage, setStage] = useState(stages[0].title);
    return (
        <section className="onboarding">
            {stages.map((item, index) => (
                <div key={`${item.title}-${index}`} className={`onboarding-item ${stage === item.title ? "onboarding-item-active" : ""}`}>
                    <h4 className="onboarding-item-title">{item.title}</h4>
                    <img src={require(`../../../assets/${item.image}`)} className="onboarding-item-image" alt="onboarding image"/>
                    <div className="onboarding-progress-bar">
                        {stages.map(subitem => (
                            <div key={"subitem"+subitem.title} className={`onboarding-progress-bar-item ${stage === subitem.title ? "onboarding-progress-bar-item-active" : ""}`}/>
                        ))}
                    </div>
                    <p className="onboarding-item-text">{item.text}</p>
                    <div className="onboarding-item-buttons">
                        <button className="onboarding-item-button onboarding-item-button-skip" onClick={()=>finalize()}>Skip</button>
                        <button className="onboarding-item-button onboarding-item-button-next" onClick={()=> {
                            if (index < stages.length - 1) {
                                setStage(stages[index + 1].title)
                            } else {
                                finalize({name: "asd", email:"asd@asd.com"});
                            }
                        }}>Next</button>
                    </div>
                </div>
            ))}
        </section>
    )
}