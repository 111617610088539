import cn from "classnames";
import { Link } from 'react-router-dom'

import { WebApp, MobileApp } from "../../assets/images/content";

import styles from "./documentation.module.sass"

const nav = [
    {
        name: 'Web App',
        component: '/docs/web',
        disabled: false
    },
    {
        name: 'Native/Mobile App',
        component: '/docs/web',
        disabled: true
    }
]

export default function Documentation() {
    return <>
        <div className={styles.documentation}>
            <div className={cn("container", styles.container)}>
                <div className={styles.start}>
                    <h1>Start Building</h1> 
                    <p>Step-by-step guides to quickly integrate Velas Account to Your Application. Choose your application type to get started</p>
                </div>

                <div className={styles.tabs}>
                    <Link className={styles.tab} to="/docs/web">
                        <div className={styles.preview}>
                            <img src={WebApp} alt="download" />
                        </div>
                        <div className={styles.subtitle}>Web App</div>
                        <div className={styles.btn}>Start Building</div>
                    </Link>

                    <Link className={cn("disabled", styles.tab)} to="#">
                        <div className={styles.preview}>
                            <img src={MobileApp} alt="download" />
                        </div>
                        <div className={styles.subtitle}>Mobile App</div>
                        <div className={styles.btn}>Start Building</div>
                    </Link>
                </div>
    
            </div>
        </div>
    </>
}