import { makeAutoObservable } from "mobx";
import {vaclient} from "../functions/vaclient";

class AccountStore {
    constructor() {
        makeAutoObservable(this);
    }

    currentSession = null;
    userInfo = null;
    csrfToken = null;
    relyingPartyAddress = null;
    //getters:

    getCurrentSession(){
        return this.currentSession;
    }

    getRelyingPartyAddress() {
        return this.relyingPartyAddress
    }

    getUserInfo() {
        return this.userInfo;
    }

    getCSRF() {
        return this.csrfToken;
    }

    //setters:

    setCurrentSession(session){
        this.currentSession = session;
        vaclient.defaultAccount(session);
        localStorage.setItem('session', JSON.stringify(session));
    }

    setCSRF(value) {
        this.csrfToken = value;
    }

    setUserInfo(value) {
        this.userInfo = value;
    }

    setRelyingPartyAddress(value){
        this.relyingPartyAddress = value;
    }

    logout() {
        this.currentSession = null;
        this.userInfo = null;
        localStorage.removeItem('session');
    }
}

export default new AccountStore();