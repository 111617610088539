import "./index.sass"
import { Benefits, Download, Hero, Hiw, Partners, Solutions, Standard, Stats, Integ } from "./landingComponents"

export default function Landing() {
    return (
        <>
            <Hero/>
            <Partners />
            <Hiw />
            <Solutions />
            <Stats />
            <Standard />
            <Integ />
            <Download />
        </>
    )
}
