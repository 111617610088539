import "./styles/app.sass"

export default function App(props) {
  return (
      <div className="App">
        {props.children}
      </div>
  );
}

