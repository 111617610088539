import {makeAutoObservable} from "mobx";
import {vaclient} from "../functions/vaclient";
import AccountStore from "./AccountStore";
import LangStore from "./LangStore";
import {toast} from "react-toastify";
import * as Web3 from "@velas/web3";
import createRPAccount from "../functions/createRPAccount";
import bs58 from 'bs58';
import {VelasAccountProgram} from '@velas/account-client';

class FunctionStore {
    constructor() {
        makeAutoObservable(this);
    }

    assignAllVariables() {
        console.log("user info", AccountStore.getUserInfo())
        const {PublicKey, Connection} = Web3;
        this.web3 = Web3;
        this.connection = new Connection(process.env.REACT_APP_NETWORK_HOST, 'singleGossip');
        this.fromPubkey = new PublicKey(process.env.REACT_APP_SPONSOR_PUB_KEY);
        this.sessionKey = new PublicKey(AccountStore.getCurrentSession().access_token_payload.ses);
        this.account = new PublicKey(bs58.decode(AccountStore.getUserInfo().account_key));
        this.feePayer = new PublicKey(process.env.REACT_APP_SPONSOR_PUB_KEY);
    }

    transactionError = null;

    handleError(error) {
        if (typeof error === "object") {
            console.error(error);
            error = error.message || error.description
        }

        const ErrorNotification = () => (
            <div className="notification-container">
                <img src={require("../assets/images/error-icon.svg").default} className="notification-icon"
                     alt="error icon"/>
                <div className="notification-content">
                    <h6 className="notification-header">{LangStore.getInstance().t("notifications.error")}</h6>
                    <p className="notification-text">{String(error).toLowerCase().includes("network") || String(error).toLowerCase().includes("fetch") ? LangStore.getInstance().t("authorization.notifications.network.error") : error}</p>
                </div>
            </div>
        )

        toast.error(<ErrorNotification/>, {
            className: "notification-error",
        });
        console.error(error);
    }

    handleSuccess(message) {
        const SuccessNotification = () => (
            <div className="notification-container">
                <img src={require("../assets/images/success-icon.svg").default} className="notification-icon"
                     alt="success icon"/>
                <div className="notification-content">
                    <h6 className="notification-header">{LangStore.getInstance().t("notifications.success")}</h6>
                    <p className="notification-text">{message}</p>
                </div>
            </div>
        )

        toast.success(<SuccessNotification/>, {
            className: "notification-success",
            autoClose: 2000
        })
    }

    login() {
        vaclient.authorize({
            csrfToken: async function () {
                const response = await fetch(`${process.env.REACT_APP_SPONSOR_HOST}/csrf/`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const {token} = await response.json();
                return token
            },
            scope: 'VelasAccountProgram:Transfer VelasAccountProgram:Execute VRPLtk4k31bDL99mn1A5mE96CUUzQ9PnftEwf2LvMiG:0'
        }, this.processAuthResult);
    }

    processAuthResult(e, authResult) {
        if (authResult && authResult.access_token_payload) {
            window.history.replaceState({}, document.title, window.location.pathname);
            AccountStore.setCurrentSession(authResult);
            console.log("authresult: ", authResult)
            this.getUserinfo(authResult.access_token);
        } else if (e) {
            window.history.replaceState({}, document.title, window.location.pathname);
            this.handleError(e);
        }
    }

    getUserinfo(access_token) {
        vaclient.userinfo(access_token, (e, result) => {
            if (e) {
                AccountStore.logout();
                if (e.error === 'failed_authorization') {
                    this.handleError(`Session terminated: ${e.description || e}`);
                } else {
                    this.handleError(`Userinfo error: ${e.description || e}`);
                }
            } else {
                console.log("userInfo", result.userinfo)
                AccountStore.setUserInfo(result.userinfo);
            }
        });
    }

    findActiveSession() {
        const session = localStorage.getItem('session');

        try {
            this.session = JSON.parse(session);
            if (this.session) {
                AccountStore.setCurrentSession(this.session);
            }
            vaclient.defaultAccount(this.session);
        } catch (_) {
        }

        return this.session;
    };


    checkActiveSession() {
        const foundSession = this.findActiveSession();
        if (foundSession) {
            this.getUserinfo(foundSession.access_token);
        } else {
            vaclient.handleRedirectCallback((...args) => this.processAuthResult(...args));
        }
    };

    async sendFormData(formData) {
        const response = await fetch(`${process.env.REACT_APP_SPONSOR_HOST}/csrf`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({formData: formData})
        });
        console.log("raw response: ", response);
        const {token} = await response.json();
        AccountStore.setCSRF(token);
    }

    async broadcastTransaction(rpData) {
        console.log("Preparing rp instruction...")
        console.log("RP Data Parsed: ", {
            programName: rpData.programName,
            programIconCid: rpData.programIconCid,
            programDomainName: rpData.programDomainName,
            programRedirectUri: rpData.programRedirectUri.split(",")
        })
        const {address, instruction, lamports} = await createRPAccount(
            this.account,
            rpData.programName,
            rpData.programIconCid,
            rpData.programDomainName,
            rpData.programRedirectUri.split(",")
        );

        console.log("RP Address: ", address[0].toBase58())
        console.log("Instruction: ", instruction)
        console.log("lamports: ", lamports)

        console.log("Preparing transactions...")
        const rpTransaction = new this.web3.Transaction().add(instruction);

        const {blockhash} = await this.connection.getRecentBlockhash();
        rpTransaction.recentBlockhash = blockhash;
        rpTransaction.feePayer = this.feePayer;

        console.log("Prepared rp transaction: ", rpTransaction)

        if (!this.transactionError) {
            console.log("Sending rp transaction...")
            await vaclient.sendTransaction(AccountStore.getCurrentSession().access_token, {
                transaction: rpTransaction.serializeMessage(),
                broadcast: true,
                csrf_token: AccountStore.getCSRF(),
            }, (err, result) => {
                if (err) {
                    this.transactionError = {title: "Error while sending rp transaction.", error: err}
                    console.log("Error while sending rp transaction: ", err);
                } else {
                    AccountStore.setRelyingPartyAddress(address[0].toBase58());
                    console.log("Successfully created rp account.", result.signature)
                }
            })
        }
    }

    async createRelyingPartyAccount(formData) {
        this.assignAllVariables();
        const rpData = (({programName, programIconCid, programDomainName, programRedirectUri}) => ({
            programName,
            programIconCid,
            programDomainName,
            programRedirectUri
        }))(formData)
        Object.keys(formData).forEach(key => {
            if (key === "programName" || key === "programIconCid" || key === "programDomainName" || key === "programRedirectUri") delete formData[key]
        });
        console.log("user data: ", formData)
        console.log("rp data: ", rpData)
        await this.sendFormData(formData);
        await this.broadcastTransaction(rpData);
    }
}

export default new FunctionStore();