import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import styles from "./Hiw.module.sass";
import { Hiw1, Hiw2, Hiw3, Hiw4, Hiwd1, Hiwd2, Hiwd3 } from "../../../assets/images/content";
import { Icon, ScrollParallax } from "../../../components";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';




const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Hiw = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 100000,
        settings: "unslick",
      },
    ],
  };

  return (
    <div className={cn("section-pb", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>
              How Velas
              <br />
              Account works
            </h2>
          <div className={styles.info}>
          Single Sign-On to DApps & Services. Passwordless authentication
          and payments with custom application access settings.{" "}
          </div>
        </div>

        <Tabs>
          <TabList className={styles.nav}>
            <Tab className={styles.btn}>For Users</Tab>
            <Tab className={styles.btn}>For Developers</Tab>
          </TabList>

          <TabPanel>
            <div className={styles.wrap}>
            <div className={styles.item}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div className={styles.details}>
                    {/* <div className={styles.number}>0{index + 1}.</div> */}
                    <h2 className={cn("h3", styles.category)}>Velas Account Authorization</h2>
                    <div className={styles.content}>Web 3.0 without passwords and seed phrases. Just press the button “Login with Velas Account”</div>
                  </div>
                </div>
                <div className={styles.col}>
                  <img
                    src={Hiw1}
                    alt="Hiw"
                  />
                </div>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div className={styles.details}>
                    {/* <div className={styles.number}>0{index + 1}.</div> */}
                    <h2 className={cn("h3", styles.category)}>Express Registration</h2>
                    <div className={styles.content}>Tap “Express Sign Up” for a quick authorization without apps or extensions. </div>
                  </div>
                </div>
                <div className={styles.col}>
                  <img
                    src={Hiw2}
                    alt="Hiw"
                  />
                </div>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div className={styles.details}>
                    {/* <div className={styles.number}>0{index + 1}.</div> */}
                    <h2 className={cn("h3", styles.category)}>Setting permissions</h2>
                    <div className={styles.content}>Customize application permissions based on your needs.</div>
                  </div>
                </div>
                <div className={styles.col}>
                  <img
                    src={Hiw3}
                    alt="Hiw"
                  />
                </div>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div className={styles.details}>
                    {/* <div className={styles.number}>0{index + 1}.</div> */}
                    <h2 className={cn("h3", styles.category)}>Cross-cutting transactions</h2>
                    <div className={styles.content}>50% of your time is saved. Transact without having to confirm every single transaction directly.</div>
                  </div>
                </div>
                <div className={styles.col}>
                  <img
                    src={Hiw4}
                    alt="Hiw"
                  />
                </div>
              </div>
            </div>
            </div>
          </TabPanel>

          <TabPanel>
          <div className={styles.wrap}>
            <div className={styles.item}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div className={styles.details}>
                    {/* <div className={styles.number}>0{index + 1}.</div> */}
                    <h2 className={cn("h3", styles.category)}>Create a relying-party account</h2>
                    <div className={styles.content}>A relying-party account is an account on the Velas blockchain, which holds important information in regards to your integration with Velas Account.</div>
                  </div>
                </div>
                <div className={styles.col}>
                  <img
                    src={Hiwd1}
                    alt="Hiw"
                  />
                </div>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div className={styles.details}>
                    {/* <div className={styles.number}>0{index + 1}.</div> */}
                    <h2 className={cn("h3", styles.category)}>Install and run a transaction sponsor</h2>
                    <div className={styles.content}>A transaction sponsor is essentially a node.js backend server which handles all the transaction broadcasting and pays the fees for the selected transaction types.</div>
                  </div>
                </div>
                <div className={styles.col}>
                  <img
                    src={Hiwd2}
                    alt="Hiw"
                  />
                </div>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div className={styles.details}>
                    {/* <div className={styles.number}>0{index + 1}.</div> */}
                    <h2 className={cn("h3", styles.category)}>Install and utilize our SDK</h2>
                    <div className={styles.content}>Account-client is our easy-to-use javascript SDK, which handles authorization, contract calling and transaction broadcasting.</div>
                  </div>
                </div>
                <div className={styles.col}>
                  <img
                    src={Hiwd3}
                    alt="Hiw"
                  />
                </div>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div className={styles.details}>
                    {/* <div className={styles.number}>0{index + 1}.</div> */}
                    <h2 className={cn("h3", styles.category)}>Check out our <br/> step-to-step tutorial</h2>
                    <div className={styles.content}>This tutorial features all the steps necessary for integrating Velas Account into your own unique application.</div>
                  </div>
                </div>
                <div className={styles.col}>
                  <iframe width="100%" height="400px" src="https://www.youtube.com/embed/mGOudTqndbc"
                          title="Velas Account Integration Tutorial" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                </div>
              </div>
            </div>
            </div>
          </TabPanel>
        </Tabs>


      </div>
    </div>
  );
};

export default Hiw;
