import "./index.sass"
import StoreContext from "../../store/RootStore";
import {useState, useEffect} from "react";
import cn from "classnames";
import {observer} from "mobx-react";
import Onboarding from "../../components/relying-party/onboarding";
import Form from "../../components/relying-party/form";
import Preloader from "../../components/relying-party/preloader";
import {ReactComponent as ErrorIcon} from "../../assets/images/relying-party/error-icon.svg";
import {ReactComponent as SuccessIcon} from "../../assets/images/relying-party/success-icon.svg";
import {ReactComponent as AuthorizeImage} from "../../assets/images/relying-party/integ.svg";

function RelyingParty() {
    const {LangStore, AccountStore, FunctionStore} = StoreContext();
    const [formStage, setFormStage] = useState("intro");

    function renderContent(stage, setStage) {
        if (AccountStore.getCurrentSession()) {
            switch (stage) {
                case "intro":
                    return <div className="rp-container"><Onboarding stages={[
                        {
                            title: "What is a Relying-Party account?",
                            image: "images/relying-party/intro-1.png",
                            text: "A relying party (RP) is a computer term used to refer to a server providing access to a secure software application. Claims-based applications, where a claim is a statement an entity makes about itself in order to establish access, are also called relying party (RP) applications."
                        },
                        {
                            title: "Why do you need to create one?",
                            image: "images/relying-party/intro-1.png",
                            text: "We utilise your RP account in various ways."
                        },
                        {
                            title: "What data do I need to provide?",
                            image: "images/relying-party/intro-1.png",
                            text: "The following data is needed to create a relying-party account: "
                        }
                    ]} finalize={()=>setStage("form")}/></div>
                case "form":
                    return <div className="rp-container"><Form stages={[
                        {
                            title: "General Info",
                            fields: [
                                {
                                    label: "Full Name",
                                    type: "text",
                                    placeholder: 'John Doe'
                                },
                                {
                                    label: "Contact Email",
                                    type: "email",
                                    placeholder: 'example@mail.com',
                                    required: true,
                                    regex: /^\S+@\S+\.\S+$/
                                },
                                {
                                    label: "Telegram Username",
                                    description: "If you have one",
                                    type: "text",
                                    placeholder: '@johndoe'
                                },
                                {
                                    label: "Company Name",
                                    type: "text",
                                    placeholder: 'Company ltd.',
                                    required: true
                                },
                                {
                                    label: "Company Website",
                                    type: "link",
                                    placeholder: 'https://company.com'
                                },
                                {
                                    label: "Short description of company's field",
                                    type: "textarea",
                                    placeholder: 'Our company is an international something...'
                                },
                                {
                                    label: "Integration Stage",
                                    type: "textarea",
                                    placeholder: 'We are currently integrating the authorization...',
                                    required: true
                                },
                            ]
                        },
                        {
                            title: "Account Details",
                            fields: [
                                {
                                    label: "Application name",
                                    description: "Will be displayed when authorizing via Velas Account",
                                    type: "text",
                                    placeholder: "e.g. Matrix: the game",
                                    propertyName: "programName",
                                    required: true,
                                    regex: /[0-9a-zA-Z]{4,}/
                                },
                                {
                                    label: "Icon CID",
                                    type: "text",
                                    description: 'ID of your logo in IPFS, displayed under sessions in Velas Account',
                                    placeholder: "e.g. QmYBK...WGdSSf2K",
                                    propertyName: "programIconCid",
                                    required: true,
                                    cidValidation: true
                                },
                                {
                                    label: "Redirect URI's",
                                    type: "text",
                                    description: 'Separate with a space',
                                    placeholder: "e.g. https://account.velas.com/relying-party http://localhost:3000/relying-party",
                                    propertyName: "programRedirectUri",
                                    regex: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                                    required: true
                                },
                                {
                                    label: "Domain name",
                                    type: "text",
                                    description: "Your main domain name.",
                                    placeholder: "e.g. https://account.velas.com/",
                                    propertyName: "programDomainName",
                                    regex: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                                    required: true
                                }
                            ]
                        }
                    ]} confirmation={{
                        title: "Confirmation",
                        description: "Make sure you check everything again before creating a relying-party account"
                    }} onSubmit={(formData)=> {
                        setStage("result");
                        FunctionStore.createRelyingPartyAccount(formData)
                    }}/></div>

                case "result":
                    if (AccountStore.getRelyingPartyAddress()) {
                        return <div className="rp-container rp-container-small">
                            <SuccessIcon className="rp-success-icon"/>
                            <h4 className="rp-success-header">Congratulations! 🎉</h4>
                            <div className="rp-success-description">
                                 <span className="rp-success-description-label">Your relying-party address is:</span>
                                <span className="rp-success-description-value">{AccountStore.getRelyingPartyAddress()}</span>
                            </div>
                            <div className="rp-success-buttons">
                                <button className="rp-success-button" type="button" onClick={()=>{
                                    navigator.clipboard.writeText(AccountStore.getRelyingPartyAddress());
                                    FunctionStore.handleSuccess("Copied to clipboard!")
                                }}>Copy address</button>
                                <button className="rp-success-button rp-success-button-big" type="button" onClick={()=>{
                                    AccountStore.setRelyingPartyAddress(null);
                                    setStage("form");
                                }}>Create another account</button>
                            </div>
                        </div>
                    } else if (FunctionStore.transactionError){
                        return <div className="rp-container rp-container-small">
                            <ErrorIcon className="rp-error-icon"/>
                            <h4 className="rp-error-header">Something went wrong...</h4>
                            <div className="rp-error-description">
                                <h6 className="rp-error-description-title">{FunctionStore.transactionError.title}</h6>
                                <p className="rp-error-description-text">{FunctionStore.transactionError.error.description}</p>
                            </div>
                            <div className="rp-error-buttons">
                                <button className="rp-error-button" type="button" onClick={()=>{
                                    FunctionStore.transactionError = null;
                                    setStage("form");
                                }}>Try again</button>
                            </div>
                        </div>
                    } else {
                        return <div className="rp-container rp-container-small">
                            <Preloader text="Sending transaction..."/>
                        </div>
                    }
            }
        } else {
            return <div className="rp-container rp-container-small">
                <h3 className={cn("h3 rp-authorize-title")}>You need to sign in with Velas Account.</h3>
                <p className="rp-authorize-description">You can also get a feel for the flow Velas Account provides while you authenticate.</p>
                <AuthorizeImage className="rp-authorize-image"/>
                <button className="rp-authorize-button" onClick={()=>FunctionStore.login()}>Sign In</button>
            </div>
        }
    }

    return <div className="rp-page">
        <h2 className={cn("h2 rp-title")}>Relying Party Account</h2>
        {/* <h1 className="rp-title">Relying Party Account</h1> */}
        <h6 className="rp-description">Single Sign-On to DApps & Services.</h6>
        {renderContent(formStage, setFormStage)}
    </div>
}

export default observer(RelyingParty)
