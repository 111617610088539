import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import { VAmain } from "../../../assets/images/content";

const Hero = ({ scrollToRef }) => {
  return (
    <div className={styles.hero}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>

          <h1 className={cn("h1", styles.title)}>
            Velas Account
          </h1>
          <div className={styles.text}>
            One-click passwordless access to Web 3.0
          </div>
          <div className={styles.btns}>
            <Link
              className={cn("button-secondary", styles.button)}
              to="/docs"
            >
              Start Integrating
            </Link>
          </div>
        </div>

        <div className={styles.gallery}>
          <div className={styles.preview}>
            <img className={styles.preview} src={VAmain} alt="va-main" />
          </div>

        </div>
      </div>
    </div>
  );
};

export default Hero;
