import React from "react";
import cn from "classnames";
import styles from "./Stats.module.sass";
import { ScrollParallax } from "../../../components";

const items = [
  {
    counter: "78",
    symbol: "%",
    content:
      "Of people have had to reset their password in the last three months",
  },
  {
    counter: "65",
    symbol: "%",
    content:
      "Of people use the same password across multiple accounts",
  },
  {
    counter: "24",
    symbol: "%",
    content:
      "Of people use a password manager",
  },
  {
    counter: "80",
    symbol: "%",
    content:
      "Of hacking-related breaches are linked to passwords",
  },
  {
    counter: "55",
    symbol: "%",
    content:
      "Of individuals and IT specialists prefer a method of protecting accounts that doesn’t involve passwords",
  },
  {
    counter: "42",
    symbol: "%",
    content:
      "Of organizations rely on sticky notes for password management",
  },
];

const Stats = () => {
  return (
    <div className={cn("section-pb", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>
            Abstract Vision
            </h2>
          <div className={styles.info}>
          90% of internet users are worried about getting their passwords hacked.
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <h2 className={cn("h2", styles.counter)}>
              {x.counter}<span className={styles.symbol}>{x.symbol}</span>
              </h2>
              {/* <div className={styles.title}>{x.title}</div> */}
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stats;
