import "./index.sass"
import preloader from "../../../assets/images/relying-party/Spinner_VA.gif"

export default function Preloader({text}) {
    return (
        <div className="preloader">
            <img src={preloader} alt="loading..." className="preloader-gif"/>
            <p className="preloader-text">{text}</p>
        </div>
    )
}